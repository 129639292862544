import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Galeria from "../components/galeria"


const SecondPage = () => (
  <Layout pageInfo={{ pageName: "galeria" }}>
    <SEO title="Galeria" />
    <Galeria/>
  </Layout>
)

export default SecondPage
