export const IMAGES = [{
  src: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_193655_optimized.jpg"),
  thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_193655_optimized.jpg"),
  thumbnailWidth: 3264,
  thumbnailHeight: 1836,
  images: [
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_193655_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_193655_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_202739_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/20180911_202739_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/20181002_190426_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/20181002_190426_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/20181204_190626_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/20181204_190626_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/IMG_3546.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/IMG_3546.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/IMG_4976.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/IMG_4976.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6530_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6530_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6549_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6549_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6664_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6664_optimized.jpg"),
    },
    {
      original: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6673_optimized.jpg"),
      thumbnail: require("../../images/gallery/ZAJĘCIA AKROGIM/img_6673_optimized.jpg"),
    },
  ],
  caption: "ZAJĘCIA AKROGIM",
},
  {
    src: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_015.jpg"),
    thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_015.jpg"),
    thumbnailWidth: 2048,
    thumbnailHeight: 1365,
    images: [
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_015.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_015.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_021.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_021.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_022.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_022.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_065.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_065.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_081.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_081.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_093.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_093.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_113.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_113.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_142.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_142.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_151.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_151.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_158.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_158.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_160.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_160.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_171.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_171.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_176.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_176.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_233.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_233.jpg"),
      },
      {
        original: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_237.jpg"),
        thumbnail: require("../../images/gallery/Gala Koncoworoczna 2023/20230619_237.jpg"),
      },
    ],
    caption: "Gala Końcoworoczna 2023",
  },
  {
    src: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_041.jpg"),
    thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_041.jpg"),
    thumbnailWidth: 2048,
    thumbnailHeight: 1365,
    images: [
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_041.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_041.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_042.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_042.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_044.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_044.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_045.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_045.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_048.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_048.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_050.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_050.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_052.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_052.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_056.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_056.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_058.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/20230619_058.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/IMG_2808.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'This bitter earth'/IMG_2808.jpg"),
      },
    ],
    caption: "CHOREOGRAFIA 'This bitter earth'",
  },
  {
    src: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_1436.jpg"),
    thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_1436.jpg"),
    thumbnailWidth: 1365,
    thumbnailHeight: 2048,
    images: [
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_1436.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_1436.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2190.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2190.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2234.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2234.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2248.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2248.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2253.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2253.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2255.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2255.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2271.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2271.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2287.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2287.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2291.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2291.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2301.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2301.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2308.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2308.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2320.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2320.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2349.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2349.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2352.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2352.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2356.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2356.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2373.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2373.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2381.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2381.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2406.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2406.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2595.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2595.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2596.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2596.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2606.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2606.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2615.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2615.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2723.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2023-24/IMG_2723.jpg"),
      },
    ],
    caption: "ZESPÓŁ TURNIEJOWY PROFIL 2023-24",
  },
  {
    src: require("../../images/gallery/ZAJĘCIA TANECZNE/20180913_193058_optimized.jpg"),
    thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/20180913_193058_optimized.jpg"),
    thumbnailWidth: 3264,
    thumbnailHeight: 1836,
    images: [
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/20180913_193058_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/20180913_193058_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/20180920_174142_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/20180920_174142_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/20181018_180606(0)_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/20181018_180606(0)_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/20191017_173526_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/20191017_173526_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2187_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2187_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2246_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2246_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2273_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2273_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2307_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2307_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2459_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2459_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2502_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2502_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2505_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2505_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2539_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2539_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2544_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2544_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2677_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2677_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2685_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2685_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2712_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZAJĘCIA TANECZNE/img_2712_optimized.jpg"),
      },
    ],
    caption: "ZAJĘCIA TANECZNE",
  },
  {
    src: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_0.jpg"),
    thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_0.jpg"),
    thumbnailWidth: 3322,
    thumbnailHeight: 3024,
    images: [
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_0.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_0.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_1.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_1.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_10.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_10.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_11.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_11.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_12.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_12.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_13.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_13.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_14.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_14.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_15.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_15.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_16.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_16.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_17.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_17.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_18.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_18.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_19.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_19.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_2.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_2.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_20.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_20.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_21.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_21.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_22.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_22.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_23.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_23.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_24.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_24.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_25.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_25.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_3.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_3.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_4.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_4.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_5.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_5.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_6.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_6.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_7.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_7.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_8.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_8.jpg"),
      },
      {
        original: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_9.jpg"),
        thumbnail: require("../../images/gallery/Spektakl Końcoworoczny Profil Dance 2021/profil_koncoworoczny_2021_9.jpg"),
      },
    ],
    caption: "Spektakl Końcoworoczny Profil Dance 2021",
  },
  {
    src: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61391418_1286657201499349_2793897468956246016_o_optimized.jpg"),
    thumbnail: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61391418_1286657201499349_2793897468956246016_o_optimized.jpg"),
    thumbnailWidth: 1920,
    thumbnailHeight: 1278,
    images: [
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61391418_1286657201499349_2793897468956246016_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61391418_1286657201499349_2793897468956246016_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61418163_1286657098166026_5798119576591925248_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61418163_1286657098166026_5798119576591925248_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61483326_1350153071801914_235959803820638208_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61483326_1350153071801914_235959803820638208_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61602538_1286657268166009_3886242643151159296_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'BREATH'/61602538_1286657268166009_3886242643151159296_o_optimized.jpg"),
      },
    ],
    caption: "CHOREOGRAFIA 'BREATH'",
  },
  {
    src: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized (1).jpg"),
    thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized (1).jpg"),
    thumbnailWidth: 2048,
    thumbnailHeight: 1152,
    images: [
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized (1).jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized (1).jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930174_662805111244463_4876775302529081749_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930662_662804864577821_7061648547956723741_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104930662_662804864577821_7061648547956723741_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104938519_662805154577792_2364257748238698999_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/104938519_662805154577792_2364257748238698999_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105027370_662804887911152_3816438750528212538_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105027370_662804887911152_3816438750528212538_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105029458_662806054577702_6795382963467630701_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105029458_662806054577702_6795382963467630701_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105029468_662805054577802_5918082848877392165_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105029468_662805054577802_5918082848877392165_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105038339_662804997911141_1262601661204406930_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105038339_662804997911141_1262601661204406930_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105038855_662804631244511_2112609996536572671_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105038855_662804631244511_2112609996536572671_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105256777_662804704577837_5482796550852787685_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105256777_662804704577837_5482796550852787685_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105375157_662806141244360_4125483905451156991_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105375157_662806141244360_4125483905451156991_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105936473_662804587911182_4830679281511558129_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/105936473_662804587911182_4830679281511558129_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180718_114446_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180718_114446_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180718_114807_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180718_114807_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180719_203543_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180719_203543_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180720_115409_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180720_115409_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180722_203951_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180722_203951_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180725_211955_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180725_211955_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180726_202321(0)_optimized.jpg"),
        thumbnail: require("../../images/gallery/OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE/20180726_202321(0)_optimized.jpg"),
      },
    ],
    caption: "OBÓZ TANECZNO-AKROBATYCZNY W CHMIELNIE",
  },
  {
    src: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61035836_1350173638466524_4209461180105752576_n_optimized.jpg"),
    thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61035836_1350173638466524_4209461180105752576_n_optimized.jpg"),
    thumbnailWidth: 587,
    thumbnailHeight: 639,
    images: [
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61035836_1350173638466524_4209461180105752576_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61035836_1350173638466524_4209461180105752576_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61323542_1286745714823831_8972800642623995904_o-(1)_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61323542_1286745714823831_8972800642623995904_o-(1)_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61324315_1286745254823877_7384449980544581632_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61324315_1286745254823877_7384449980544581632_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61548020_1350173741799847_7294826872209145856_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61548020_1350173741799847_7294826872209145856_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61589914_1350173845133170_299708951128178688_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61589914_1350173845133170_299708951128178688_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61688785_1350174468466441_43101130686726144_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61688785_1350174468466441_43101130686726144_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61825809_1286745774823825_5400234257262051328_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'ALL THE REASONS WHY...'/61825809_1286745774823825_5400234257262051328_o_optimized.jpg"),
      },
    ],
    caption: "CHOREOGRAFIA 'ALL THE REASONS WHY...'",
  },
  {
    src: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-297_optimized.jpg"),
    thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-297_optimized.jpg"),
    thumbnailWidth: 4256,
    thumbnailHeight: 2832,
    images: [
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-297_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-297_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-306_optimized (1).jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-306_optimized (1).jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-321_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-321_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-349_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-349_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-361_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-361_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-375_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-375_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-386_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-386_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-448_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-448_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-483_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-483_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-513_optimized.jpg"),
        thumbnail: require("../../images/gallery/ZESPÓŁ TURNIEJOWY PROFIL 2019-20/profil-513_optimized.jpg"),
      },
    ],
    caption: "ZESPÓŁ TURNIEJOWY PROFIL 2019-20",
  },
  {
    src: require("../../images/gallery/TURNIEJE TANECZNE/0df313aa-b093-4d0b-a78c-50dcffd00914.jpg"),
    thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/0df313aa-b093-4d0b-a78c-50dcffd00914.jpg"),
    thumbnailWidth: 1440,
    thumbnailHeight: 1080,
    images: [
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/0df313aa-b093-4d0b-a78c-50dcffd00914.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/0df313aa-b093-4d0b-a78c-50dcffd00914.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20181117_141856_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20181117_141856_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20190427_134847(0)_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20190427_134847(0)_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20190527_182747_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20190527_182747_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20190602_170904_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20190602_170904_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20200119_152857_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20200119_152857_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20200301_213720_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20200301_213720_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/20200301_214014_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/20200301_214014_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/47308239_2198759670157737_4323923806595842048_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/47308239_2198759670157737_4323923806595842048_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/47434547_2198759903491047_8740383323534131200_o_optimized.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/47434547_2198759903491047_8740383323534131200_o_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/IMG_3804.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/IMG_3804.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/IMG_4840.png"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/IMG_4840.png"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/IMG_5407.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/IMG_5407.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/IMG_5408.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/IMG_5408.jpg"),
      },
      {
        original: require("../../images/gallery/TURNIEJE TANECZNE/c931d855-1587-44c6-8cb1-23952599c1d1.jpg"),
        thumbnail: require("../../images/gallery/TURNIEJE TANECZNE/c931d855-1587-44c6-8cb1-23952599c1d1.jpg"),
      },
    ],
    caption: "TURNIEJE TANECZNE",
  },
  {
    src: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_180.jpg"),
    thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_180.jpg"),
    thumbnailWidth: 2048,
    thumbnailHeight: 1365,
    images: [
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_180.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_180.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_181.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_181.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_185.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_185.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_187.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_187.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_191.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_191.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_192.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_192.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_197.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_197.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_200.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_200.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_202.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'RETROSPEKCJE'/20230619_202.jpg"),
      },
    ],
    caption: "CHOREOGRAFIA 'RETROSPEKCJE'",
  },
  {
    src: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0028_dpp_optimized.jpg"),
    thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0028_dpp_optimized.jpg"),
    thumbnailWidth: 4898,
    thumbnailHeight: 3265,
    images: [
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0028_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0028_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0031_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0031_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0061_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0061_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0162_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0162_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0173_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0173_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0205_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0205_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0211_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0211_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0224_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0224_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0245_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0245_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0273_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0273_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0318_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0318_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0330_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_0330_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9782_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9782_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9809_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9809_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9836_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9836_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9842_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9842_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9852_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9852_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9876_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9876_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9890_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9890_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9931_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9931_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9942_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9942_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9960_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9960_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9995_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9995_dpp_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9996_dpp_optimized.jpg"),
        thumbnail: require("../../images/gallery/SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY/img_9996_dpp_optimized.jpg"),
      },
    ],
    caption: "SPEKTAKL KOŃCOWOROCZNY PROFIL DANCE COMPANY",
  },
  {
    src: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80126751_532902447567114_3294889589965062144_n_optimized.jpg"),
    thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80126751_532902447567114_3294889589965062144_n_optimized.jpg"),
    thumbnailWidth: 2083,
    thumbnailHeight: 3239,
    images: [
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80126751_532902447567114_3294889589965062144_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80126751_532902447567114_3294889589965062144_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80218560_591154028115417_5488276469740208128_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80218560_591154028115417_5488276469740208128_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80330366_434938574079473_4857682439264272384_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80330366_434938574079473_4857682439264272384_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80402098_546514055899254_5823654540146114560_n_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/80402098_546514055899254_5823654540146114560_n_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-034_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-034_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-052_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-052_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-056_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-056_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-127_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-127_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-145_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-145_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-152_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-152_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-165_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-165_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-172_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-172_optimized.jpg"),
      },
      {
        original: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-187_optimized.jpg"),
        thumbnail: require("../../images/gallery/CHOREOGRAFIA 'LANTERNS LIT'/profil-187_optimized.jpg"),
      },
    ],
    caption: "CHOREOGRAFIA 'LANTERNS LIT'",
  },
]
