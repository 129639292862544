import React from "react"
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap"
import "./style.scss"
import "react-image-gallery/styles/css/image-gallery.css"
import { MdClose } from "react-icons/md"


import PropTypes from "prop-types"
import Gallery from "react-grid-gallery"
import ImageGallery from "react-image-gallery"
import { IMAGES } from "./galeriaData"


class Folders extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showFolders: true,
      visibleFolder: 0,
    }

  }

  switchGallerySetFolderIndex = (index) => {
    this.setState({ showFolders: !this.state.showFolders, visibleFolder: index })
  }

  render() {
    var images =
      this.props.images.map((i) => {
        i.customOverlay = (
          <div style={captionStyle}>
            <div style={{margin: 'auto'}}>{i.caption}</div>
          </div>);
        return i;
      });

    return (
      <div style={{
        display: "block",
        minHeight: "1px",
        width: "100%",
        border: "0px solid #ddd",
        overflow: "auto",
      }}>
        <br/>
        <Gallery
          images={images}
          enableImageSelection={false}
          enableLightbox={true}
          onClickThumbnail={this.switchGallerySetFolderIndex}
          rowHeight={350}

        />
        <GalleryModal show={!this.state.showFolders} visibleFolder={this.state.visibleFolder}
                      onHide={()=>this.switchGallerySetFolderIndex(0)}/>
      </div>
    )
  }
}


function GalleryModal(props) {
  return (
    <Modal{...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={"modalGallery"}
    >
      <Modal.Header closeButton>
        {IMAGES[props.visibleFolder].caption}
      </Modal.Header>
      <Modal.Body>
        <ImageGallery
          items={IMAGES[props.visibleFolder].images}
          enableImageSelection={false}
          enableLightbox={true}
        />
      </Modal.Body>
    </Modal>
  )
}

Folders.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      srcset: PropTypes.array,
      caption: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
      ]),
      thumbnailWidth: PropTypes.number.isRequired,
      thumbnailHeight: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default function Galeria(props) {
  return (
    <Container>
      <Folders images={IMAGES}/>
    </Container>
  )
}

const captionStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  // maxHeight: "240px",
  overflow: "hidden",
  color: "blanchedalmond",
  position: "absolute",
  bottom: "0",
  textAlign: 'center',
  width: "100%",
  height: "100%",
  padding: "2px",
  fontSize: "26px",
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center'
};
